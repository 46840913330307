import { Component, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { StateService } from '@shared/services';

@Component({
  selector: 'thevatra-heart-rate',
  templateUrl: './heart-rate.component.html',
  styleUrls: ['./heart-rate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeartRateComponent implements OnDestroy {
  destroyed$ = new Subject<boolean>();
  @Input() heartRate = 0;
  console = console;
  public maxHeartRate = 180;

  constructor(
    public readonly stateService: StateService,
  ) {
  }

  public getHeartRatePercentage(): number {
    return Math.round((this.heartRate / this.maxHeartRate) * 100);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
