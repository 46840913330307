import { envDefaults, EnvironmentSettings } from './environment.type';

export const environment: EnvironmentSettings = {
  ...envDefaults,
  buildNumber: `993`,
  production: true,
  sentryOrigin: 'staging.vatra.live',
  envName: 'staging',
  apiUrl: 'https://api-staging.vatra.live',
  janus: {
    debug: false,
    stringRoomIds: false,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAY5j3wYWfpiWxptbjPdW0YyL1LHj0mpfk',
    authDomain: 'stusan-staging.firebaseapp.com',
    projectId: 'stusan-staging',
    storageBucket: 'stusan-staging.appspot.com',
    messagingSenderId: '521908088094',
    appId: '1:521908088094:web:bd30ebce3584a9e38c6d0f'
  },
};
