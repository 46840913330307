import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BackgroundEffectsType, ThemeType } from '@enums';
import { MediaService, Resolution } from '@shared/services/media.service';
import { PopupRef } from '@shared/services/popup/popup.ref';
import { SettingsService } from '@shared/services/settings.service';
import { StateService } from '@shared/services/state.service';
import { JanusService } from '@shared/services';
import { PlatformDetectorService } from '@shared/services/platform-detector/platform-detector.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { VideoEffectsService } from '@shared/services/video-effects/video-effects.service';
import { BACKGROUND_EFFECTS } from '@shared/components/settings/constants/background-effects.constants';
import { BackgroundEffect } from '@shared/components/settings/models/background-effect.model';
import { ViewService } from '@shared/services/view/view.service';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { CustomizationService } from '../../../services/customization.service';
import { CustomizationModel } from '../../../models/customization.model';
import { MetricsService } from '../../../services/metrics.service';

type SettingsDialogData = { stream: MediaStream; closeByEsc: boolean; withHeartRateControl: boolean } & MatDialogConfig;

@Component({
  selector: 'thevatra-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('previewVideo')
  previewVideo: ElementRef<HTMLVideoElement> | null;

  @ViewChild('previewVideoWithEffects')
  previewVideoWithEffects: ElementRef<HTMLCanvasElement>;

  public stream: MediaStream | null;
  public isMobilePlatform: boolean;
  public isBackgroundEffectsEditable: boolean;
  public form: FormGroup;
  public videoInputs: MediaDeviceInfo[] = [];
  public audioInputs: MediaDeviceInfo[] = [];
  public videoResolutions: any[] = [];
  public backgroundEffects: BackgroundEffect[] = BACKGROUND_EFFECTS;
  public resolutionFailed = false;
  public resolutionSelectionAvailable = false;
  public isDesktopView$ = this.viewService.isDesktopView$.asObservable();
  public currentBackgroundEffect$ = this.stateService.currentBackgroundEffect$.asObservable();
  public customConfig: CustomizationModel;
  private dropDownRef: PopupRef | null;
  private destroyed$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SettingsDialogData,
    private readonly dialogRef: PopupRef<SettingsComponent>,
    private readonly platformDetectorService: PlatformDetectorService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private janusService: JanusService,
    public stateService: StateService,
    private mediaService: MediaService,
    private videoEffectsService: VideoEffectsService,
    private cdr: ChangeDetectorRef,
    private readonly viewService: ViewService,
    public customizationService: CustomizationService,
    public readonly metricsService: MetricsService,
  ) {
    this.customConfig = customizationService.config;
  }

  ngOnInit(): void {
    AnalyticsService.addBreadcrumb('ui', 'Show settings form.');

    this.isMobilePlatform = this.platformDetectorService.isMobile();
    this.isBackgroundEffectsEditable = !this.platformDetectorService.isSafari() && !this.platformDetectorService.isFirefox();

    console.log(this.data);

    this.initInputAndOutputDevices();
    this.initSettingsForm();

    this.handleThemeControlChanges();
    this.handleCameraControlChanges();
    this.handleResolutionControlChanges();
    this.handleSimulcastControlChanges();
    this.handleMicControlChanges();
    this.handleDialogRefCloseEvents();
  }


  onChecked(val: any): void {
    this.stateService.isHeartRateEnabled$.next(val.target.checked);
    this.metricsService.toggleReadMetrics(val.target.checked);
  }

  async ngAfterViewInit(): Promise<void> {
    await this.settingsService.updateDevices();
    // this.stateService.isLoggedIn$.pipe(takeUntil(this.destroyed$)).subscribe((isLoggedIn) => {
    //   this.resolutionSelectionAvailable = !isLoggedIn;
    // });

    if (this.data.stream && this.stateService.isCamEnabled) {
      const previewStream = this.data.stream.clone();

      // this.data.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());

      await this.setPreviewStream(previewStream);
      this.currentBackgroundEffectChanges();
    } else {
      await this.setInitialAudioDeviceId(this.data.stream);
      await this.setInitialVideoDeviceId(this.data.stream);

      this.getVideoPreview(this.stateService.videoDeviceId).then(async (stream: MediaStream | void): Promise<void> => {
        if (!stream) {
          if (this.isMobilePlatform) {
            await this.updateForm(this.mediaService.getMediaStream());
          }
          return;
        }

        if (!this.stateService.videoDeviceId) {
          await this.settingsService.updateDevices();
        }

        await this.setPreviewStream(stream);
        this.currentBackgroundEffectChanges();
      });
    }
  }

  public close(): void {
    if (!this.stateService.isCamEnabled) {
      this.mediaService.stopVideoStreamTracks(this.data.stream);
      this.mediaService.stopVideoStreamTracks(this.stream);
    }

    this.dialogRef.close();

    if (!!this.dropDownRef) {
      this.dropDownRef.close();
    }

    if (this.stream) {
      this.mediaService.stopStream(this.stream);
    }
  }

  public chooseBackground(value: BackgroundEffectsType): void {
    if (this.viewService.isMobileView$.value || value === this.stateService.currentBackgroundEffect) {
      return;
    }

    this.stateService.currentBackgroundEffect = value;
    this.cdr.detectChanges();
  }

  public onDropdown(ref: PopupRef | null) {
    this.dropDownRef = ref;
  }

  private async getCurrentVideoId(stream: MediaStream | null, fallbackDeviceId: string | null): Promise<string | null> {
    if (!stream) {
      console.log('settings fallback to initial video ' + fallbackDeviceId);
      return fallbackDeviceId;
    }

    const currentVideoDevice = this.stateService.videoDeviceId || this.mediaService.getStreamVideoId(stream);

    if (!currentVideoDevice) {
      return null;
    }

    if (currentVideoDevice === 'default') {
      return currentVideoDevice;
    } else {
      console.log('settings found stream video ' + currentVideoDevice);
      return currentVideoDevice;
    }
  }

  private async getCurrentAudioId(stream: MediaStream | null): Promise<string | null> {
    if (!stream) {
      return null;
    }

    const streamDeviceId = this.stateService.audioDeviceId || this.mediaService.getStreamAudioDeviceId(stream);

    if (streamDeviceId) {
      if (streamDeviceId === 'default') {
        // console.log('settings fallback to default mic', stream, this.stateService.audioDeviceId);
      }
      // console.log('settings init stream mic ' + streamDeviceId, this.stateService.audioDeviceId);
      return streamDeviceId;
    }

    // console.log('settings fallback to null mic', stream, this.stateService.audioDeviceId);
    return null;
  }

  private initSettingsForm(): void {
    this.form = this.fb.group({
      theme: [this.stateService.theme === ThemeType.dark, [Validators.required]],
      camera: [this.stateService.videoDeviceId, [Validators.required]],
      resolution: ['', this.resolutionSelectionAvailable ? [Validators.required] : []],
      simulcast: [this.stateService.simulcastEnabled],
      mic: [this.stateService.audioDeviceId, [Validators.required]],
      isHeartRateEnabled: [this.stateService.isHeartRateEnabled]
    });
  }

  private async setInitialAudioDeviceId(stream: MediaStream | null): Promise<void> {
    const audioDeviceId = this.stateService.audioDeviceId;

    if (audioDeviceId && !!this.audioInputs.find((audioInput: MediaDeviceInfo) => audioInput.deviceId === audioDeviceId)) {
      return;
    }

    if (!stream) {
      const { deviceId } = await this.settingsService.getDefaultAudioDevice() as MediaDeviceInfo;
      this.stateService.audioDeviceId = deviceId;
      return;
    }

    this.stateService.audioDeviceId = await this.getCurrentAudioId(stream);
  }

  private async setInitialVideoDeviceId(stream: MediaStream | null): Promise<void> {
    const videoDeviceId = this.stateService.videoDeviceId;

    if (videoDeviceId && !!this.videoInputs.find((videoInput: MediaDeviceInfo) => videoInput.deviceId === videoDeviceId)) {
      return;
    }

    if (!stream) {
      const { deviceId } = await this.settingsService.getDefaultVideoDevice() as MediaDeviceInfo;
      this.stateService.videoDeviceId = deviceId;
      return;
    }

    this.stateService.videoDeviceId = await this.getCurrentVideoId(stream, this.stateService.videoDeviceId);
  }

  private initInputAndOutputDevices(): void {
    this.settingsService.getDevices().pipe(takeUntil(this.destroyed$)).subscribe((devices) => {
      this.videoInputs = devices.videoDevices;
      this.audioInputs = devices.audioDevices;
      console.log('initInputAndOutputDevices', devices);

      this.cdr.markForCheck();
    });
  }

  private handleThemeControlChanges(): void {
    this.form.get('theme')?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isDark) => {
        this.stateService.theme = isDark ? ThemeType.dark : ThemeType.light;
        this.cdr.markForCheck();
      });
  }

  private handleCameraControlChanges(): void {
    this.form.get('camera')?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (deviceId) => {
        if (deviceId === this.stateService.videoDeviceId) {
          return;
        }

        // this.currentVideoDeviceId = deviceId;
        this.resolutionFailed = false;
        this.stateService.videoDeviceId = deviceId;
        await this.updatePreviewFromDeviceId(deviceId);
        this.updatePreviewDependsOnChanges();
      });
  }

  private handleResolutionControlChanges(): void {
    this.form.get('resolution')?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((resolution) => {
        if (this.stream) {
          this.mediaService.applyResolution(this.stream, resolution).then((success) => {
            if (success) {
              this.stateService.resolution = resolution;
              this.resolutionFailed = false;
            } else {
              this.resolutionFailed = true;
              this.form.get('resolution')?.setValue(this.getCurrentResolution(this.stream), {
                onlySelf: true, emitModelToViewChange: true, emitEvent: false, emitViewToModelChange: true
              });
            }
            this.cdr.markForCheck();
          });
        }
      });
  }

  private handleSimulcastControlChanges(): void {
    this.form.get('simulcast')?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((enabled) => this.stateService.simulcastEnabled = enabled);
  }

  private handleMicControlChanges(): void {
    this.form.get('mic')?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((deviceId) => {
        if (deviceId === this.stateService.audioDeviceId) {
          return;
        }

        this.stateService.audioDeviceId = deviceId;
        this.updatePreviewDependsOnChanges();
      });
  }

  private handleDialogRefCloseEvents(): void {
    this.dialogRef.overlay.backdropClick()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.close());

    this.dialogRef.overlay.keydownEvents()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: KeyboardEvent) => {
        if (event.code === 'Escape') {
          this.close();
        }
      });
  }

  // private optionsFromDevices(devices: MediaDeviceInfo[]) {
  //   return devices.map((device) => ({ label: device.label, value: device.deviceId }));
  // }

  private optionsFromResolutions(resolutions: Resolution[]) {
    return resolutions.map(this.optionFromResolution);
  }

  private optionFromResolution(resolution: Resolution) {
    return { label: resolution.name, value: resolution };
  }

  private async updatePreviewFromDeviceId(deviceId: string) {
    if (this.stream) {
      this.stopStream(this.stream);
    }

    const stream = await this.getVideoPreview(deviceId);

    if (stream) {
      await this.updateResolutions(stream);
      await this.setPreviewStream(stream);
    }
  }

  private async updateResolutions(stream: MediaStream) {
    const supported = await this.mediaService.getSupportedResolutions(stream, true);

    this.videoResolutions = this.optionsFromResolutions(supported);
    this.cdr.markForCheck();
  }

  private async updatePreviewWithBackgroundEffect(): Promise<void> {
    if (this.stateService.currentBackgroundEffect === BackgroundEffectsType.none) {
      return Promise.resolve();
    }

    if (!this.videoEffectsService.isBackgroundEffectApplied()) {
      this.videoEffectsService.stopRedrawingCanvas();
      this.videoEffectsService.sendVideoChangesForRedrawing(/*replacedStream*/).subscribe(async () => {
        const stream = this.videoEffectsService.getCanvasMediaStream();

        return this.updatePreview(stream);
      });

      return Promise.resolve();
    } else {
      const stream = this.videoEffectsService.getCanvasMediaStream();

      return this.updatePreview(stream);
    }
  }

  private async setPreviewStream(stream: MediaStream): Promise<void> {
    // preview DOM element is not set for mobile look
    if (this.previewVideo) {
      this.stream = stream;
    }

    await this.updatePreview(stream);

    return Promise.resolve();
  }

  private async updatePreview(stream: MediaStream): Promise<void> {
    if (this.previewVideo) {
      this.previewVideo.nativeElement.srcObject = stream;
      this.previewVideo.nativeElement.muted = true;

      await this.updateForm(this.stream);

      // return this.previewVideo.nativeElement.play();
    }

    return Promise.resolve();
  }

  private async updateForm(stream: any = null): Promise<void> {
    AnalyticsService.addBreadcrumb('ui', 'Update settings form.');

    const data: any = {};

    if (stream) {
      // Get resolution
      await this.updateResolutions(stream);
      let resolution: any = this.getCurrentResolution(stream);

      if (resolution) {
        resolution = this.optionFromResolution(resolution);

        if (resolution) {
          data.resolution = resolution.value;
        }
      }
    }

    // Get Microphone device id
    data.mic = this.stateService.audioDeviceId;

    // Get Camera device id
    data.camera = this.stateService.videoDeviceId;

    // Update form with new values
    this.form.patchValue(data, { emitEvent: false });
  }

  private async getVideoPreview(deviceId?: string | null): Promise<MediaStream | void> {
    if (this.isMobilePlatform) {
      return;
    }

    const constraints = {
      video: !!deviceId ? {
        deviceId: {
          exact: deviceId,
        },
      } : true,
      audio: !!this.stateService.audioDeviceId ? {
        deviceId: {
          exact: this.stateService.audioDeviceId,
        },
      } : true,
    };

    return this.mediaService.getMediaStream(constraints);
  }

  private getCurrentResolution(stream: MediaStream | null): Resolution | null {
    return stream ? this.mediaService.getStreamResolution(stream) : null;
  }

  private stopStream(stream: MediaStream | null = this.stream) {
    if (stream) {
      this.mediaService.stopStream(stream);
      stream = null;
    }
  }

  private currentBackgroundEffectChanges(): void {
    this.stateService.currentBackgroundEffect$.asObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updatePreviewDependsOnChanges());
  }

  private updatePreviewDependsOnChanges(): void {
    if (this.stateService.currentBackgroundEffect === BackgroundEffectsType.none) {
      if (!this.stream) {
        return;
      }

      this.setPreviewStream(this.stream);
    } else {
      this.updatePreviewWithBackgroundEffect();
    }
  }

  ngOnDestroy(): void {
    if (this.previewVideo) {
      this.previewVideo.nativeElement.srcObject = null;
    }

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
