import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, finalize, map, takeUntil, tap } from 'rxjs/operators';
// @ts-ignore
import { WebRTCStats } from '@peermetrics/webrtc-stats';

import { ConnectionInfoModel, ScreenShareRtdb, UserMedia, UserMetrics, UserOptions, Video, VoiceState } from '@models';
import { JanusService, StateService } from '@shared/services';
import { AnalyticsService } from '@shared/services/analytics.service';
import { VideoType } from '@enums';
import { RealtimeDatabaseService } from '../../../services/rtdb.service';
import { CustomizationService } from '../../../services/customization.service';
import { TokenService } from '@shared/services/token.service';
import { Reaction } from '../../../models/message';
import { AuthService } from '@shared/services/auth.service';
import { PlatformDetectorService } from '@shared/services/platform-detector/platform-detector.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { ReactionsService } from '@shared/services/reactions.service';
import { VideoEffectsService } from '@shared/services/video-effects/video-effects.service';
import { RealtimeDatabaseSubscriptionsService } from '../../../services/rtdb-subscriptions.service';
import { generateStringBitrate } from 'src/app/stusan/utils/stats.util';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from '@shared/services/app.service';
import { ControlsService } from '@shared/services/controls.service';

@Component({
  selector: 'thevatra-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnDestroy, OnInit {
  @Input() layoutName = 'leader';

  @Input() set source(video: Video) {
    const isSourceChanged: boolean = this.input !== video;
    // console.log('tile source ' + video.id + ' isSourceChanged:' + isSourceChanged);
    if (video) {
      this.id = video.id;
      this.input = video;

      if (isSourceChanged) {
        this.sourceChanged$.next(true);
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  @Input('isHidden') set isHidden(value: boolean) {
    if (this.input && this.input.pluginHandle) {
      if (this.id === this.janusService.getLocalUserId()) {
        // console.log('[send] STOP sending - owner video');
        return;
      }
      if (this.id === this.janusService.localScreenShareId) {
        // console.log('[send] STOP sending - share screen');
        return;
      }
      // console.log(`configure invoked ${this.id} ${!value}`);
      // this.input.pluginHandle.send({ message: { request: 'configure', video: !value } });
    }
  }

  public showMoreMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showTech = false;
  public techData: ConnectionInfoModel = {};
  public mutedMic: boolean;
  public mutedCam = true;
  public talking: boolean;
  public currentBackgroundEffect$ = this.stateService.currentBackgroundEffect$.asObservable();
  public id = 0;
  public cameraMuting$ = this.controlsService.cameraMuting$;
  public micMuting$ = this.controlsService.micMuting$;

  users: any = {};
  uid = '';
  country = '';
  city = '';
  countryName = '';
  weather = 0;
  screenOrientation: boolean | null = null;
  timezone = '';
  points = true;
  rtdbHeartRate = 0;
  isHeartRateEnabled = false;
  localHeartRate = 0;

  @Output() heartRate = 0;
  styles: Partial<CSSStyleDeclaration> = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '300px',
    height: '300px',
    background: 'transparent',
    zIndex: '1000',
    transform: 'translate(-50%,-50%)'
  };
  options: AnimationOptions = {
    path: '/assets/liked.json',
    autoplay: false,
  };
  liked: any;
  private animationItem: AnimationItem;
  @ViewChild('video') videoEl: ElementRef<HTMLVideoElement>;

  get video(): HTMLVideoElement {
    return this.videoEl.nativeElement;
  }

  private input: Video;
  private webrtcStats: WebRTCStats = null;
  private readonly destroyed$ = new Subject<boolean>();
  private readonly sourceChanged$ = new Subject<boolean>();

  get isOwn(): boolean {
    return !this.input?.remote;
  }

  get isScreen(): boolean {
    return this.input?.type === VideoType.screen
      || this.appService.isScreen(this.name);
  }

  get isPlaying(): boolean {
    return !this.video?.paused || false;
  }

  get name(): string {
    return this.input?.name || '';
  }

  constructor(
    public janusService: JanusService,
    public stateService: StateService,
    public auth: AuthService,
    public readonly customizationService: CustomizationService,
    private readonly elementRef: ElementRef,
    private readonly rtdb: RealtimeDatabaseService,
    public readonly rtdbSubscriptionService: RealtimeDatabaseSubscriptionsService,
    private readonly tokenService: TokenService,
    public readonly platformDetectorService: PlatformDetectorService,
    private readonly videoEffectsService: VideoEffectsService,
    private ngZone: NgZone,
    private readonly reactionsService: ReactionsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private appService: AppService,
    private controlsService: ControlsService
  ) {
  }

  changeStream() {
    // console.log('this.input.pluginHandle: ', this.input);
    this.input.pluginHandle.send({ message: { substream: 1, request: 'configure' } });
  }

  ngOnInit() {
    // console.log('tile ngOnInit', this.id);
    this.video.muted = true;
    this.sourceChanged$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.initNewSource());

    // force first source change event
    this.sourceChanged$.next(true);
  }

  public get isAudioEnabled(): boolean {
    return this.stateService.isAudioEnabled;
  }

  public get isVideoEnabled(): boolean {
    return this.stateService.isVideoEnabled;
  }

  public toggleMic(): void {
    this.controlsService.toggleMic();
  }

  public toggleCam(): void {
    this.controlsService.toggleCam();
  }

  private initNewSource() {
    console.log('tile initNewSource', this.id);
    // attach stream to video
    // copied from Janus demo
    // https://janus.conf.meetecho.com/mvideoroomtest.js
    try {
      this.video.srcObject = this.input.stream;
    } catch (e1) {
      try {
        this.video.src = URL.createObjectURL(this.input.stream as unknown as MediaSource);
      } catch (e2) {
        console.error('Error attaching stream to element', e1, e2);
      }
    }

    const userInfo = this.rtdbSubscriptionService.getUserInfo(this.id, !this.input.remote);

    userInfo.media$
      .pipe(
        takeUntil(this.destroyed$),
        takeUntil(this.sourceChanged$),
        filter(v => v !== null)    // was null once for screenshare in leader view
      )
      .subscribe((media: UserMedia) => {
        this.mutedCam = !media.video;
        this.mutedMic = !media.audio;

        // console.log(`tile media ${this.id}, mutedCam:${this.mutedCam}, mutedMic: ${this.mutedMic},
        //   ${JSON.stringify(media)}.`);

        this.changeDetectorRef.detectChanges();
      });

    if (this.isScreen) {
      // always show video for screen share tiles
      this.mutedCam = false;
    }

    if (!userInfo && !this.isScreen) {
      console.error('tile ' + this.id + ' no user info');
    }

    this.janusService.voiceState$.pipe(
      filter(v => v.userId === this.id),
      takeUntil(this.destroyed$),
      takeUntil(this.sourceChanged$),
    ).subscribe((voiceState: VoiceState) => {
      this.talking = voiceState.talking;
      this.changeDetectorRef.detectChanges();
    });

    this.tokenService.isHost$.pipe(
      takeUntil(this.destroyed$),
      takeUntil(this.sourceChanged$),
    ).subscribe(this.showMoreMenu$);

    this.rtdbSubscriptionService.screenShareSubscription$
      .pipe(
        takeUntil(this.destroyed$),
        takeUntil(this.sourceChanged$),
        map((value: { [userId: string]: ScreenShareRtdb } | null) => value && value[this.stateService.userId]?.screenShareId),
        filter((value: number | null | undefined) => !!value),
        map(screenShareId => screenShareId !== this.id)
      )
      .subscribe(this.showMoreMenu$);


    if (userInfo) {  // can be null for screen share
      userInfo.options$
        .pipe(
          untilDestroyed(this),
          takeUntil(this.sourceChanged$),
        )
        .subscribe((options: UserOptions | null) => {
          if (options) {
            this.country = options.country ? options.country.toLowerCase() : '';
            this.city = options.city || '';
            this.countryName = options.countryName || '';
            this.weather = options.weather || 0;
            this.timezone = options.timezone || '';
            this.changeDetectorRef.markForCheck();
          }
        });

      userInfo.metrics$
        .pipe(
          untilDestroyed(this),
          takeUntil(this.sourceChanged$),
        )
        .subscribe((metrics: UserMetrics | null) => {
          if (metrics) {
            this.rtdbHeartRate = metrics.heartRate || 0;
            this.changeDetectorRef.markForCheck();
          }
        });
      this.stateService.heartRate$.asObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (localHeartRate: number) => {
            this.localHeartRate = localHeartRate || 0;
            this.changeDetectorRef.markForCheck();
          }
        );
      this.stateService.isHeartRateEnabled$.asObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (isHeartRateEnabled: boolean) => {
            this.isHeartRateEnabled = isHeartRateEnabled;
            this.changeDetectorRef.markForCheck();
          }
        );
    }

    // TODO extract to service
    if (this.customizationService.config.isLikesEnabled) {
      this.rtdbSubscriptionService.usersSubscription$.pipe(
        takeUntil(this.destroyed$),
        tap(users => this.users = users),
        filter((users) => users.hasOwnProperty(this.id)),
        map(() => this.users[this.id]?.uid)
      ).subscribe(uid => {
        this.uid = uid;

        if (!this.uid) {
          return;
        }

        this.getLike$(this.id).pipe(
          takeUntil(this.destroyed$),
        ).subscribe((likes: Reaction[]) => {
          this.liked = likes[0]?.likeId === this.id;
          if (this.liked) {
            this.stateService.isAnimationActive$.next(true);
            this.play();
          } else {
            this.stateService.isAnimationActive$.next(false);
            this.stop();
          }
        });
      });
    }

    // TODO extract to service
    const roomName = this.stateService.getRoomName();
    const isSafariMobile = (this.platformDetectorService.isBrowserSafari() && this.platformDetectorService.isMobile());
    this.setRTDBCurrentUidValue(roomName);
    if (isSafariMobile) {
      this.rtdb.set(`rooms/${roomName}/users/${this.stateService.userId}/screenOrientation`, false);

      const portrait = window.matchMedia('(orientation: landscape)');

      portrait.addEventListener('change', (e) => {
        this.rtdb.set(`rooms/${roomName}/users/${this.stateService.userId}/screenOrientation`, e.matches);
      });

      this.rtdb.subscribe<boolean>(`rooms/${roomName}/users/${this.id}/screenOrientation`)
        .subscribe(screenOrientation => {
          this.screenOrientation = screenOrientation;
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  public toggleTechInfo(event: MouseEvent) {
    this.showTech = !this.showTech;

    if (this.showTech) {
      this.getStatsFromWebrtc();
    } else {
      this.webrtcStats.removePeer(this.id);
    }

    event.stopPropagation();
  }

  public fullScreen() {
    const currentElement = this.elementRef.nativeElement;

    const fullscreenFunction = currentElement.requestFullscreen ||
      currentElement.requestFullScreen ||
      currentElement.webkitRequestFullscreen ||
      currentElement.webkitRequestFullScreen ||
      currentElement.mozRequestFullScree ||
      currentElement.msRequestFullscreen;

    fullscreenFunction.apply(currentElement).then(() => {
      console.log('[fullScreen] ok');
    });
  }

  public toFahrenheit(kelvins: number) {
    return (((kelvins - 273.15) * 1.8) + 32).toFixed();
  }

  public toCelsius(kelvins: number) {
    return (kelvins - 273.15).toFixed();
  }

  public getLocalTime(timezone: string): string {
    if (timezone === 'Europe/Kyiv') {
      timezone = 'Europe/Kiev';
    }

    return timezone && new Date().toLocaleString('en-US', { timeZone: timezone });
  }

  public animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem.setSpeed(2);
  }

  private async setRTDBCurrentUidValue(roomName: string): Promise<void> {
    if (this.customizationService.config.isLikesEnabled) {
      if (this.isScreen) {
        await this.rtdb.set(`rooms/${roomName}/users/${this.id}/uid`, this.auth.currentUser?.id);
      } else {
        await this.rtdb.set(`rooms/${roomName}/users/${this.stateService.userId}/uid`, this.auth.currentUser?.id);
      }
    }
  }

  private getLike$(userId: any): Observable<any> {
    let unsubscribe = () => {
    };
    return new Observable(observer => {
      unsubscribe = this.reactionsService.getLike(userId).onSnapshot((snap) => {
        // this.userId = snap.docs && snap.docs[0]?.id;
        observer.next(
          snap.docs.map(doc => ({
            ...(doc.data() as any),
            likeId: doc.data().likeId,
          })) as any
        );
      }, error => {
        console.log(error);
      });
    }).pipe(finalize(unsubscribe));
  }

  private getStatsFromWebrtc() {
    this.techData = {};

    // if (
    //   this.input.stream &&
    //   this.input.stream.getVideoTracks() &&
    //   this.input.stream.getVideoTracks()[0] &&
    //   this.input.stream.getVideoTracks()[0].getSettings()
    // ) {
    //   const settings = this.input.stream.getVideoTracks()[0].getSettings();
    //
    //   Object.assign(this.techData, settings);
    // }

    const inputPc = this.input.pluginHandle.webrtcStuff.pc;

    this.webrtcStats = new WebRTCStats({ getStatsInterval: 3000 });

    this.webrtcStats.addConnection({
      pc: inputPc,
      peerId: this.id, // any string that helps you identify this peer,
      remote: !this.isOwn, // optional, override the global remote flag
    });

    let sparseCounter = 0;
    this.webrtcStats.on('stats', (ev: any) => {
      if ((this.input.pluginHandle as any).videoCodec) {
        this.techData.videoCodec = (this.input.pluginHandle as any).videoCodec;
      }

      // supports only Chrome now
      const inVideoTrackLabel = this.input.stream?.getVideoTracks().map(t => t.label)[0];
      const inAudioTrackLabel = ''; // NOT IMPLEMENTED YET

      const newTechData = generateStringBitrate(this.isOwn, inVideoTrackLabel, inAudioTrackLabel, ev.data.video, ev.data.audio);
      this.techData = {
        ...this.techData,
        ...newTechData,
      };

      this.changeDetectorRef.markForCheck();

      sparseCounter++;

      if (sparseCounter % 10 === 0) {
        AnalyticsService.reportStats(this.techData);
      }
    });
  }

  private play(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem?.play();
    });
  }

  private stop(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem?.stop();
    });
  }

  togglePoints() {
    this.points = !this.points;
  };

  ngOnDestroy() {
    if (this.webrtcStats) {
      this.webrtcStats.removePeer(this.id);
      this.webrtcStats = null;
    }

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
