import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomizationModel } from '../models/customization.model';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class CustomizationService {
  readonly siriusHost = 'grouproom.sirius.video';
  // readonly siriusHost = 'localhost'; // for tests
  // readonly paincaveHost = 'app.paincave.live';
  readonly paincaveHost = 'localhost'; // for tests

  config: CustomizationModel = {
    homeLogo: '/assets/stusan/images/logo.png', // if null - hide logo
    roomLogo: '/assets/stusan/images/stusan-logo-small.svg',
    homeShowDescription: true, // true by default, false for grouproom.sirius.video.
    isRecordingEnabled: true, // true by default, false for sirius
    mediaEchoCancellation: { ideal: true }, // by default
    mediaAutoGainControl: { ideal: true }, // by default
    mediaNoiseSuppression: { ideal: true }, // by default
    favicon: './assets/favicon/favicon-vatra.png',
    faviconApple: './assets/favicon/favicon-vatra-apple.png',
    bitrate: 250000,
    screenBitrate: 1500000,
    title: 'TheVatra',
    isAuthEnabled: false,
    feedbackUrl: 'https://trembit.atlassian.net/servicedesk/customer/portal/1',
    isLayoutSwitchEnabled: true,
    homeShowAuthors: true,
    roomName: true,
    layout: true,
    leader: false,
    service: 'vatra',
    isChatShown: false,
    theme: 'light',
    isProfileEnabled: false,
    isLikesEnabled: false,
    isWeatherEnabled: true,
    roomNameLength: 4,
    userNameLength: 4,
    isHeartRateEnabled: true,
  };

  constructor(private titleService: Title,) {
    const isCloudVatra = false; // window.location.hostname === 'cloud.vatra.live';

    if (this.isSirius()) {
      // this.config.homeLogo = '/assets/stusan/images/sirius.logo.png';
      this.config.homeLogo = '/assets/stusan/images/sirius.home.logo.png'; // oct-18
      this.config.roomLogo = '/assets/stusan/images/sirius.room.logo.png';
      this.config.homeShowDescription = false;
      this.config.isRecordingEnabled = false;
      this.config.favicon = './assets/favicon/favicon-sirius.png';
      this.config.faviconApple = './assets/favicon/favicon-sirius-apple.png';
      this.config.title = 'Sirius video';
      this.config.server = 'wss://sirius-wss.janus.vatra.live:443';
      this.config.feedbackUrl = 'https://go.sirius.video/feedback-grouproom';
      this.config.isLayoutSwitchEnabled = false;
      this.config.roomNameLength = 1;
      this.config.userNameLength = 1;
      this.config.homeShowAuthors = false;
      this.config.isHeartRateEnabled = false;
    } else if (isCloudVatra) {
      // using cloud version on janus
      this.config.server = 'wss://janus-cloud.trembit.com:8289';
    } else if (this.isColaborator()) {
      this.config.isAuthEnabled = true;
    } else if (this.isPainCave()) {
      this.config.homeLogo = '/assets/stusan/images/paincave.logo.svg';
      this.config.roomLogo = '/assets/stusan/images/paincave.logo.svg';
      this.config.title = 'PainCave';
      this.config.homeShowDescription = false;
      this.config.isRecordingEnabled = false;
      this.config.roomName = false;
      this.config.layout = false;
      this.config.leader = true;
      this.config.service = 'paincave';
      this.config.isChatShown = true;
      this.config.theme = 'blue';
      this.config.favicon = './assets/favicon/favicon-paincave.png';
      this.config.faviconApple = './assets/favicon/favicon-paincave-apple.png';
      this.config.isProfileEnabled = true;
      this.config.isLikesEnabled = true;
      this.config.isWeatherEnabled = false;
    }

    if (this.isBot()) {
      this.config.isAuthEnabled = false;
    }
  }

  public isSirius(): boolean {
    return window.location.hostname.indexOf(this.siriusHost) !== -1;
  }

  public isColaborator(): boolean {
    return environment.jwtAuth !== null;
  }

  public isPainCave(): boolean {
    return environment.signupFlowEnabled;
  }

  // public isPainCave(): boolean {
  //   return (window.location.pathname === '/signup')
  //     || (window.location.pathname === '/paincave');
  // }
  //
  // public isPainCave(): boolean {
  //   return window.location.hostname.indexOf(this.paincaveHost) !== -1;
  // }

  public isBot(): boolean {
    const params = new URL(window.location.href).searchParams;
    const recordingBot = params.get('recordingBot');
    return recordingBot === 'true';
  }

  set title(value: string | undefined) {
    if (value && !this.isSirius() && !this.isPainCave()) {
      this.config.title = value;
      this.titleService.setTitle(value);
    }
  }

  set logoUrl(value: string | undefined) {
    if (value && !this.isSirius() && !this.isPainCave()) {
      this.config.homeLogo = value;
    }
  }
}
