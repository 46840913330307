import { Injectable } from '@angular/core';

@Injectable({  providedIn: 'root' })
export class SoundService {
  private joinAudio: HTMLAudioElement;
  private leaveAudio: HTMLAudioElement;
  private recordingStartAudio: HTMLAudioElement;
  private recordingStopAudio: HTMLAudioElement;

  constructor() { }

  public playJoin() {
    if (!this.joinAudio) {
      this.joinAudio = new Audio('../../../../assets/media/join.mp3');
    }
    this.playAudio(this.joinAudio);
  }
  public playLeave() {
    if (!this.leaveAudio) {
      this.leaveAudio = new Audio('../../../../assets/media/leave.mp3');
    }
    this.playAudio(this.leaveAudio);
  }

  public playRecordingStarted() {
    if (!this.recordingStartAudio) {
      this.recordingStartAudio = new Audio('../../../../assets/media/recording-started.mp3');
    }
    this.playAudio(this.recordingStartAudio);
  }
  public playRecordingStopped() {
    if (!this.recordingStopAudio) {
      this.recordingStopAudio = new Audio('../../../../assets/media/recording-stopped.mp3');
    }
    this.playAudio(this.recordingStopAudio);
  }

  private playAudio(audio: HTMLAudioElement) {
    try {
      audio.play().
        catch((e) => console.log('Failed to play audio', e));
    } catch (e) {
      console.log('Failed to play audio', e);
    }
  }
}
