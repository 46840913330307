<div *ngIf="isDesktopView$ | async else oldView" class="settings" [formGroup]="form">
  <div class="settings-header">
    <div class="settings-header-title">Settings</div>
    <stusan-icon class="settings-header-close" icon="settings-close" (click)="close()"></stusan-icon>
  </div>
  <div class="settings-content">
    <div class="settings-controls">
      <div class="settings-controls-camera">
        <div class="camera-header">
          <stusan-icon class="camera-header-icon" icon="settings-cam"></stusan-icon>
          <div class="camera-header-title">Camera</div>
        </div>
        <mat-select class="settings-controls-control"
                    formControlName="camera"
                    panelClass="thevatra-select">
          <mat-option *ngFor="let device of videoInputs" [value]="device.deviceId">
            {{ device.label }}
          </mat-option>
        </mat-select>
      </div>

      <div class="separator"></div>

      <div class="settings-controls-audio">
        <div class="audio-header">
          <stusan-icon class="audio-header-icon" icon="settings-mic"></stusan-icon>
          <div class="audio-header-title">Audio</div>
        </div>
        <mat-select class="settings-controls-control"
                    formControlName="mic"
                    panelClass="thevatra-select">
          <mat-option *ngFor="let device of audioInputs" [value]="device.deviceId">
            {{ device.label }}
          </mat-option>
        </mat-select>
      </div>
      <div *ngIf="customConfig.isHeartRateEnabled && data.withHeartRateControl">
        <div class="separator"></div>
        <div class="heartRate">
          <label class="checkbox" [ngClass]="{
          'disabled': metricsService.isConnecting$ | async,
          'connected': stateService.heartRate > 0
          }">
            <input
              type="checkbox"
              [checked]="stateService.isHeartRateEnabled"
              formControlName="isHeartRateEnabled"
              (input)="onChecked($event)">
            {{(metricsService.isConnecting$ | async) ? 'Connecting' : stateService.heartRate > 0 ? 'Connected' : 'Display Heart rate'}}
          </label>
        </div>
      </div>
    </div>
    <div class="background-effects">
      <div class="background-effects-video-wrapper">
        <video *ngIf="!isMobilePlatform"
               #previewVideo
               class="background-effects-video"
               muted
               autoplay
               disablepictureinpicture
               playsinline>
        </video>
      </div>
      <div *ngIf="isBackgroundEffectsEditable" class="background-effects-header">
        <stusan-icon class="background-effects-header-icon" icon="background-effects"></stusan-icon>
        <div class="background-effects-header-title">Choose background effects</div>
      </div>
      <div *ngIf="isBackgroundEffectsEditable" class="choose-background">
        <button *ngFor="let backgroundEffect of backgroundEffects"
                class="choose-background-effect"
                [ngClass]="backgroundEffect.class"
                [class.active]="(currentBackgroundEffect$ | async) === backgroundEffect.value"
                (click)="chooseBackground(backgroundEffect.value)">
          <span class="text">{{ backgroundEffect.name }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #oldView>
  <form [formGroup]="form">
    <div class="old-view-container">
      <stusan-icon icon="close" class="close" (click)="close()"></stusan-icon>
      <h1>Settings</h1>
      <div class="scrollable">
        <!-- <div class="theme">
          <stusan-icon icon="theme"></stusan-icon>
          <span>Switch to {{!!form.get('theme')?.value ? 'light' : 'dark'}} style</span>
          <thevatra-toggle formControlName="theme"></thevatra-toggle>
        </div> -->
        <div class="camera">
          <div class="caption">
            <stusan-icon icon="cam-on"></stusan-icon>
            <span>
            Web camera
          </span>
          </div>

          <mat-select formControlName="camera" panelClass="thevatra-select">
            <mat-option *ngFor="let device of videoInputs" [value]="device.deviceId">
              {{ device.label }}
            </mat-option>
          </mat-select>
          <!--        <thevatra-dropdown-->
          <!--          formControlName="camera"-->
          <!--          [options]="videoInputs"-->
          <!--          (popupEvent)="onDropdown($event)">-->
          <!--        </thevatra-dropdown>-->
          <ng-container *ngIf="resolutionSelectionAvailable">
            <thevatra-dropdown
              class="resolutionSelect"
              formControlName="resolution"
              [options]="videoResolutions"
              (popupEvent)="onDropdown($event)">
            </thevatra-dropdown>
            <span class="red" *ngIf="resolutionFailed">Selected resolution currently unavailable. Try another</span>
          </ng-container>
          <div class="simulcast" *ngIf="resolutionSelectionAvailable">
            <span>Simulcast</span>
            <thevatra-toggle formControlName="simulcast"></thevatra-toggle>
          </div>
        </div>
        <div class="audio">
          <div class="caption">
            <stusan-icon icon="mic-on"></stusan-icon>Microphone
          </div>
          <mat-select formControlName="mic"
                      panelClass="thevatra-select">
            <mat-option *ngFor="let device of audioInputs" [value]="device.deviceId">
              {{ device.label }}
            </mat-option>
          </mat-select>
          <!--        <thevatra-dropdown-->
          <!--          formControlName="mic"-->
          <!--          [options]="audioInputs"-->
          <!--          (popupEvent)="onDropdown($event)"-->
          <!--        ></thevatra-dropdown>-->
        </div>
        <div *ngIf="customConfig.isHeartRateEnabled && data.withHeartRateControl">
          <div class="heartRate">
            <label class="checkbox">
              <input type="checkbox" [checked]="stateService.isHeartRateEnabled" formControlName="isHeartRateEnabled" (input)="onChecked($event)">
              Display heart rate
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
