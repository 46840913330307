import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Results, SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { JanusService } from '@shared/services/janus.service';
import { MediaService } from '@shared/services/media.service';
import { BackgroundEffectsType } from '@enums';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CLEAR_TIMEOUT, SET_TIMEOUT } from '@shared/services/video-effects/video-effects.constants';
import { StateService } from '@shared/services';
import { AnalyticsService } from '@shared/services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class VideoEffectsService implements OnDestroy {
  public stream$: BehaviorSubject<MediaStream> = new BehaviorSubject<MediaStream>(new MediaStream());

  public canvasMediaStream: MediaStream = new MediaStream();
  private selfieSegmentation: SelfieSegmentation;
  private inputVideoElement: HTMLVideoElement = document.createElement('video');
  private outputCanvasElement: HTMLCanvasElement = document.createElement('canvas');
  private timerWorker: Worker | null;

  constructor(
    private readonly janusService: JanusService,
    private readonly mediaService: MediaService,
    private readonly stateService: StateService,
    private readonly ngZone: NgZone
  ) {
    this.handleRedrawingCanvasWithEffect();
  }

  public isBackgroundEffectApplied(): boolean {
    return !!this.timerWorker;
  }

  public handleRedrawingCanvasWithEffect(effect: BackgroundEffectsType.blur = BackgroundEffectsType.blur): void {
    if (!this.selfieSegmentation) {
      this.selfieSegmentation = this.initSelfieSegmentation();
    }

    this.ngZone.runOutsideAngular(() =>
      this.selfieSegmentation.onResults((results: Results) => {
        if (this.timerWorker) {
          this.timerWorker.postMessage({ id: SET_TIMEOUT });
        }

        this.applyBackgroundEffectOnCanvas(results, effect);
      })
    );
  }

  public sendVideoChangesForRedrawing(replacedStream?: MediaStream | null): Observable<MediaStream | null> {
    if (replacedStream) {
      this.initCanvasStylesSettings(this.outputCanvasElement, replacedStream);
      this.startSendingVideo(replacedStream);

      return of(replacedStream);
    } else {
      return this.initLocalMediaStream()
        .pipe(
          tap(() => {
            this.initCanvasStylesSettings(this.outputCanvasElement);
            this.startSendingVideo();
          })
        );
    }
  }

  public stopRedrawingCanvas(): void {
    if (this.timerWorker) {
      this.timerWorker.postMessage({ id: CLEAR_TIMEOUT });
      this.timerWorker.terminate();
      this.timerWorker = null;
    }

    this.resetSelfieSegmentation();
    this.mediaService.turnOffWebCamLight(this.stream$?.value);
    this.mediaService.turnOffWebCamLight(this.canvasMediaStream);
    this.mediaService.turnOffWebCamLight(this.janusService.localStream$?.value);
    this.stream$.next(new MediaStream());
  }

  public getCanvasMediaStream(): MediaStream {
    const typedOutputCanvasElement = this.outputCanvasElement as HTMLCanvasElement & { captureStream: (frameRate: number) => MediaStream };
    const localAudioStreamTracks: MediaStreamTrack[] = this.stream$.value?.getAudioTracks() || [];
    const canvasVideoTracks: MediaStreamTrack[] = typedOutputCanvasElement.captureStream(60).getTracks() || [];

    return new MediaStream([...localAudioStreamTracks, ...canvasVideoTracks]);
  }

  private initCanvasStylesSettings(htmlElement: HTMLCanvasElement, stream: MediaStream = this.stream$.value): void {
    const videoSettings = this.mediaService.getSettingsForVideoOffer(stream);

    if (!htmlElement || !videoSettings) {
      return;
    }

    htmlElement.width = videoSettings.width || 1920;
    htmlElement.height = videoSettings.height || 1080;
  }

  private startSendingVideo(replacedStream?: MediaStream): void {
    const videoSettings = this.mediaService.getSettingsForVideoOffer(replacedStream || this.stream$.value);

    if (!this.inputVideoElement || !videoSettings) {
      return;
    }

    if (!this.timerWorker) {
      this.initTimeWebWorker();
    }

    this.inputVideoElement.width = videoSettings.width || 1920;
    this.inputVideoElement.height = videoSettings.height || 1080;
    this.inputVideoElement.autoplay = true;
    this.inputVideoElement.srcObject = replacedStream || this.stream$.value;
    this.inputVideoElement.muted = true; /* Do something with it */
    this.inputVideoElement.onloadeddata = () => {
      this.inputVideoElement.play().then(() => {
        this.resetSelfieSegmentation();
        this.selfieSegmentation.send({ image: this.inputVideoElement })
          .then(() => {
            if (!this.timerWorker) {
              return;
            }

            this.timerWorker.onmessage = () => {
              this.selfieSegmentation.send({ image: this.inputVideoElement });
            };
          })
          .catch((error) => {
            console.error('Error in VideoEffects service', error);
            this.stopRedrawingCanvas();
          });
      });
    };
  }

  public initSelfieSegmentation() {
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file: string) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1/${file}`
    });

    selfieSegmentation.setOptions({ selfieMode: false, modelSelection: 1 });

    return selfieSegmentation;
  }

  public sendChangedStream(videoRoomPlugin: any, replacedStream?: MediaStream): void {
    this.stopRedrawingCanvas();
    this.sendVideoChangesForRedrawing(replacedStream).subscribe(() => {
      const canvasMediaStream = this.getCanvasMediaStream();

      this.canvasMediaStream = canvasMediaStream;

      const tracks = [];

      if (canvasMediaStream.getAudioTracks().length > 0) {
        tracks.push({ type: 'audio', replace: true, capture: canvasMediaStream.getAudioTracks()[0], recv: true });
      } else if (this.stateService.audioDeviceId) {
        tracks.push({
          type: 'audio',
          replace: true,
          capture: { deviceId: { ideal: this.stateService.audioDeviceId } },
          recv: true,
        });
      }

      if (canvasMediaStream.getVideoTracks().length > 0) {
        tracks.push({ type: 'video', replace: true, capture: canvasMediaStream.getVideoTracks()[0], recv: true });
      }

      videoRoomPlugin.createOffer({
        tracks,
        // If DTX is enabled, munge the SDP
        customizeSdp: (jsep: any) => jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;usedtx=1'),
        success: (jsep: any) => videoRoomPlugin.send({ message: { video: true, audio: true }, jsep }),
        error: (error: any) => {
          const msg = 'Problem creating offer in video effects';
          console.error(msg, error);
          AnalyticsService.captureException(error, msg);
        },
      });
    });
  }

  private applyBackgroundEffectOnCanvas(results: Results, effect: BackgroundEffectsType.blur): void {
    const canvasCtx = this.outputCanvasElement.getContext('2d');
    const { width, height } = this.outputCanvasElement;

    if (!canvasCtx) {
      return;
    }

    canvasCtx.save();
    canvasCtx.clearRect(0, 0, width, height);

    // draw segmentation mask canvasCtx filter a 'none';
    canvasCtx.globalCompositeOperation = 'source-over';
    canvasCtx.drawImage(results.segmentationMask, 0, 0, width, height);

    // draw ImageFrame on top
    canvasCtx.globalCompositeOperation = 'source-in';
    canvasCtx.drawImage(results.image, 0, 0, width, height);

    // blurred background
    if (effect === BackgroundEffectsType.blur) {
      canvasCtx.filter = 'blur(6px)';
      canvasCtx.globalCompositeOperation = 'destination-over';
    }

    canvasCtx.drawImage(results.image, 0, 0, width, height);
    canvasCtx.restore();
  }

  private resetSelfieSegmentation(): void {
    if (this.selfieSegmentation) {
      this.selfieSegmentation.reset();
    }
  }

  private initLocalMediaStream(): Observable<MediaStream | null> {
    return from(this.mediaService.getMediaStream())
      .pipe(tap((mediaStream: MediaStream | null) => this.stream$.next(mediaStream || new MediaStream())));
  }

  private initTimeWebWorker(): void {
    if (typeof Worker !== 'undefined') {
      this.timerWorker = new Worker(new URL('./timer.worker', import.meta.url), { type: 'module' });
    } else {
      console.error('Web Worker is not supported.');
    }
  }

  ngOnDestroy() {
    this.stopRedrawingCanvas();

    if (this.timerWorker) {
      this.timerWorker.terminate();
      this.timerWorker = null;
    }
  }
}
