import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { RealtimeDatabaseService } from './rtdb.service';
import { RecordingState } from '../enums/recording-state';
import { StateService } from '@shared/services';
import { distinctUntilChanged, filter, map, pluck, take } from 'rxjs/operators';
import { UserStatus } from '../models/user-status';
import { OptionsService } from '@shared/services/options.service';
import { ScreenShareRtdb, UserInfo, UserMedia } from '@models';
import { CustomizationService } from './customization.service';

@Injectable({
  providedIn: 'root'
})
export class RealtimeDatabaseSubscriptionsService {
  private readonly screenShare$: BehaviorSubject<{ [userId: string]: ScreenShareRtdb } | null> =
    new BehaviorSubject<{ [userId: string]: ScreenShareRtdb } | null>(null);
  private readonly recording$: BehaviorSubject<RecordingState | null> = new BehaviorSubject<RecordingState | null>(null);
  private readonly forceMutedState$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  private readonly users$: BehaviorSubject<any | null> = new BehaviorSubject(null);
  public readonly localUser$ = new BehaviorSubject<UserInfo | null>(null);

  public screenShareSubscription$: Observable<{ [userId: string]: ScreenShareRtdb } | null> =
    this.screenShare$.asObservable();
  public recordingSubscription$: Observable<RecordingState | null> =
    this.recording$.asObservable() as Observable<RecordingState | null>;
  public micStateSubscription$: Observable<boolean | null> =
    this.forceMutedState$.asObservable();
  public usersSubscription$: Observable<any> = this.users$.asObservable().pipe(filter(value => value !== null));

  private userInfos = new Map<number, UserInfo>();

  constructor(
    private readonly rtdb: RealtimeDatabaseService,
    private readonly stateService: StateService,
    private readonly optionsService: OptionsService,
    private readonly customizationService: CustomizationService
  ) {
  }

  public userSubscription(userId: string): Observable<UserStatus> {
    return this.usersSubscription$
      .pipe(
        filter((value) => value.hasOwnProperty(userId)),
        pluck(userId)
      );
  }

  public initAllSubscriptions(handleId: number): void {
    combineLatest([
      this.rtdb.connected$.pipe(
        filter((value: boolean) => value),
        take(1)
      ),
      this.stateService.roomName$.pipe(
        filter((v) => !!v),
        map((v) => v as string),
        take(1)
      )
    ])
      .subscribe(async ([connected, roomName]) => {
        // DEBUG reset room state
        // await this.rtdb.set(`rooms/${roomName}/users`, {});

        // initial RTDB calls
        this.rtdb.addOnDisconnectHook(
          `rooms/${roomName}/users/${handleId}`,
          null
        );
        this.rtdb.subscribe<{ [key: string]: ScreenShareRtdb } | null>(`rooms/${roomName}/screenShare`).subscribe(this.screenShare$);
        this.rtdb.subscribe<RecordingState>(`rooms/${roomName}/stateRecording`).subscribe(this.recording$);
        this.rtdb.subscribe<boolean | null>(`rooms/${roomName}/users/${handleId}/isForceMuted`).subscribe(this.forceMutedState$);
        // this.rtdb.subscribe<any>(`rooms/${roomName}/users`).subscribe(this.users$);

        await this.sendLocalMediaState(handleId);

        if (!this.stateService.isRecordingBot && this.isWeatherEnabled()) {
          // init weather and flag
          const location: any = await this.optionsService.getUserCountry().toPromise();
          const weather: any = await this.optionsService.getUserWeather(location.city).toPromise();
          await this.rtdb.set(`rooms/${roomName}/users/${handleId}/options`, {
            city: location.city,
            countryName: location.country_name,
            country: location.country,
            timezone: location.timezone,
            weather: weather.main.temp
          });
        }
      });
  }

  public async sendHeartRate(handleId: string, heartRate: number | null): Promise<any> {
    const roomName = this.stateService.getRoomName();
    await this.rtdb.set(`rooms/${roomName}/users/${handleId}/metrics`, heartRate);
  }

  public async forceTurnMicOff(handleId: number): Promise<any> {
    const roomName = this.stateService.getRoomName();
    await this.rtdb.set(`rooms/${roomName}/users/${handleId}/isForceMuted`, true);
  }

  public async removeForcedMicMarker(handleId: string) {
    const roomName = this.stateService.getRoomName();
    await this.rtdb.set(`rooms/${roomName}/users/${handleId}/isForceMuted`, false);
  }

  // async toggleRaiseHand(handleId: string): Promise<any> {
  //   const roomName = this.stateService.getRoomName();
  //   await this.rtdb.set(`rooms/${roomName}/users/${handleId}/displayName`, this.stateService.userName);
  //   await this.rtdb.set(`rooms/${roomName}/users/${handleId}/isRaisedHand`, this.stateService.isHandRaised);
  // }
  //
  // async hideRaisedHand(handleId: string): Promise<any> {
  //   const roomName = this.stateService.getRoomName();
  //   await this.rtdb.update(`rooms/${roomName}/users/${handleId}/isRaisedHand`, false);
  // }

  public sendScreenShareState(screenShareId: number | null = null): void {
    const roomName = this.stateService.roomName;
    const timestamp = this.getCurrentMillisecondsUTC();
    const userId = this.stateService.userId;

    this.rtdb.set(`rooms/${roomName}/screenShare/${userId}`, screenShareId ? { screenShareId, timestamp } : null);

    if (typeof screenShareId === 'number') {
      this.rtdb.addOnDisconnectHook(`rooms/${roomName}/screenShare/${userId}`);
    } else {
      this.rtdb.removeOnDisconnectHook(`rooms/${roomName}/screenShare/${userId}`);
    }
  }

  public async sendLocalMediaState(handleId: number) {
    const roomName = this.stateService.getRoomName();
    const video = this.stateService.videoEnabled;
    const audio = this.stateService.audioEnabled;

    const userInfo = this.getUserInfo(handleId, true);

    userInfo.video = video;
    userInfo.audio = audio;
    const media = new UserMedia(video, audio);
    // console.log(`sendLocalMediaState ${JSON.stringify(media)}`);
    userInfo.media$.next(media);

    await this.rtdb.set(`rooms/${roomName}/users/${handleId}/media`, {
      video,
      audio,
    });
  }

  public addUserInfo(handleId: number, userName: string, isLocal: boolean = false): UserInfo {
    const existingUser = this.userInfos.get(handleId);
    if (existingUser) {
      // already added
      // console.warn('rtdb user already added ' + handleId);
      existingUser.userName = userName;
      return existingUser;
    }

    const userInfo = new UserInfo(handleId, userName, isLocal);
    const roomName = this.stateService.getRoomName();
    this.userInfos.set(handleId, userInfo);

    // TODO RTDB: try to read options from loaded rtdb 'users' first
    if (!userInfo.isScreen() && this.isWeatherEnabled()) {
      userInfo.options$$ = this.rtdb
        .subscribe<any>(`rooms/${roomName}/users/${handleId}/options`)
        .pipe(distinctUntilChanged())
        .subscribe(userInfo.options$);
    }
    if(this.customizationService.config.isHeartRateEnabled) {
      userInfo.metrics$$ =
        this.rtdb.subscribe<number>(`rooms/${roomName}/users/${handleId}/metrics`).subscribe((data) => {
          userInfo.metrics$.next({ heartRate: data });
        });
    }
    if (!userInfo.isScreen() && !userInfo.isLocal) {
      // subscribe to mic mute, cam mute changes
      userInfo.media$$ = this.rtdb
        .subscribe<UserMedia>(`rooms/${roomName}/users/${handleId}/media`)
        .pipe(filter(m => !!m), distinctUntilChanged())
        .subscribe((data) => {
          userInfo.audio = data.audio;
          userInfo.video = data.video;

          // inform views: video tiles and participants
          userInfo.media$.next(data);
        });
    }

    if (userInfo.isLocal) {
      this.localUser$.next(userInfo);
    }
    return userInfo;
  }

  // TODO return Observable to avoid raising error for views that try to render removed users
  public getUserInfo(handleId: number, isLocal: boolean): UserInfo {
    const value = this.userInfos.get(handleId) || null;
    if (!value) {
      // user name will be updated later
      return this.addUserInfo(handleId, '', isLocal);
    }
    return value;
  }

  public removeUserInfo(handleId: number) {
    const userInfo = this.userInfos.get(handleId);
    if (userInfo && userInfo.options$$) {
      userInfo.options$$.unsubscribe();
      userInfo.options$$ = null;
    }
    if (userInfo && userInfo.media$$) {
      userInfo.media$$.unsubscribe();
      userInfo.media$$ = null;
    }

    // TODO: remove from map. But it's ok for now to let it grow
    // and avoid null pointers
  }

  private isWeatherEnabled() {
    return this.customizationService.config.isWeatherEnabled || this.customizationService.config.isLikesEnabled;
  }

  private getCurrentMillisecondsUTC(): number {
    const now = new Date();

    return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
  }
}
