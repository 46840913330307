import { Injectable } from '@angular/core';
import defaultsDeep from 'lodash.defaultsdeep';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { StateService } from './state.service';
import { catchError, map } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
// @ts-ignore
import vad from 'voice-activity-detection';

interface VadType {
  connect(): void;
  disconnect(): void;
  destroy(): void;
}

export type Resolution = {
  name: string;
  width: number;
  height: number;
  alt?: number;
};

export const RESOLUTIONS: Resolution[] = [
  { name: 'SD', width: 320, height: 240, alt: 180 },
  { name: 'HQ', width: 640, height: 480, alt: 360 },
  { name: 'HD', width: 1280, height: 960, alt: 720 },
];

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  // public stream: any;

  private camera$ = new BehaviorSubject<MediaStream | null>(null);
  private audioContext: AudioContext | null = null;
  private vad: VadType | null = null;

  voiceActivityDetect(stream: MediaStream | null) {
    console.log('voiceActivityDetect', this.vad, stream);
    // delayed creation of AudioContext, because it requires a user-gesture
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    this.vad?.destroy();
    this.vad = null;

    if (!stream || !this.state.audioEnabled) {
      return;
    }

    const options = {
      onVoiceStart: () => {
        // console.log('voice start');
        this.state.isVoiceActive$.next(true);
      },
      onVoiceStop: () => {
        // console.log('voice stop');
        this.state.isVoiceActive$.next(false);
      },
      onUpdate: (val: any) => {
        // console.log('curr val:', val);
      }
    };
    this.vad = vad(this.audioContext, stream, options);
  }

  set camera(stream: MediaStream | null) {
    if (stream === null) {
      this.camera?.getTracks().forEach((track) => track.stop());
    } else {
      // this.saveConstraints(stream);
    }
    this.camera$.next(stream);
  }

  get camera(): MediaStream | null {
    return this.camera$.getValue();
  }

  get cameraIsAlive(): boolean {
    const video = this.camera?.getVideoTracks()[0];
    if (video) {
      return video.readyState === 'live';
    }
    return false;
  }

  private defaultsConstraints: MediaStreamConstraints = {
    video: {
      aspectRatio: 1.77777777778,

      width: 1280,
      height: 720,
      frameRate: 24,
    },
    audio: true,
  };
  private prevConstraints: MediaStreamConstraints = this.state.constraints;

  constructor(private state: StateService) {
    this.state.videoDeviceId$.subscribe((deviceId) => {
      if (!!deviceId) {
        defaultsDeep(this.defaultsConstraints, { video: { deviceId: { ideal: deviceId } } });
      }
    });

    this.state.audioDeviceId$.subscribe((deviceId) => {
      if (!!deviceId) {
        defaultsDeep(this.defaultsConstraints, { audio: { deviceId: { ideal: deviceId } } });
      }
    });
  }

  public async getMediaStream(options?: MediaStreamConstraints): Promise<MediaStream> {
    const constraints = {};
    defaultsDeep(constraints, options, this.prevConstraints, this.defaultsConstraints);

    return navigator.mediaDevices.getUserMedia(constraints);
  }

  public devicesGetUserMedia(): Observable<boolean> {
    const constraints = {};
    defaultsDeep(constraints, this.prevConstraints, this.defaultsConstraints);

    return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(
      catchError((error) => {
        if ('NotAllowedError' === error.name) { // User blocked cam
          this.state.isCamBanned = true;
        } else if ('NotFoundError' === error.name) { // user don't have cam
          this.state.isCamBanned = true;
          return of(true);
        }
        return of(false);
      }),
      map((stream: MediaStream | boolean) => {
        if (stream instanceof MediaStream) {
          stream.getTracks().forEach((track: MediaStreamTrack) => {
            track.stop();
            stream.removeTrack(track);
          });
        }
        return !!stream;
      }));
  }

  // getDevices(): Promise<MediaDeviceInfo[]> {
  //   return navigator.mediaDevices.enumerateDevices();
  // }

  // TODO StanR consider removing as we have videoDeviceId and audioDeviceId saved separately
  public saveConstraints(stream: MediaStream | null,
    videoDeviceId: string | undefined = undefined,
    audioDeviceId: string | undefined = undefined,
  ) {
    this.prevConstraints = {
      video: this.getSettingsForVideoOffer(stream, videoDeviceId) || undefined,
      audio: {
        ...this.state.constraints.audio,
        deviceId: audioDeviceId,
      }
    };
    this.state.constraints = this.prevConstraints;
  }

  public getSettingsForVideoOffer(maybeStream: MediaStream | null, currentVideoDeviceId: string = ''): {
    deviceId?: string;
    width?: number;
    height?: number;
  } | null {
    if (maybeStream !== null) {
      const stream: MediaStream = maybeStream;
      const video = 'getVideoTracks' in stream ? stream.getVideoTracks()[0] : null;
      if (!('getVideoTracks' in stream)) {
        Sentry.captureMessage(`Detected missing getVideoTracks ${stream.constructor.name}.`);
      }

      if (video) {
        const settings = video.getSettings();

        return {
          width: settings.width || 1280,
          height: settings.height || 720,
          deviceId: currentVideoDeviceId || this.state.videoDeviceId as string
        };
      }
    } else {
      if (this.prevConstraints) {
        const settings = (this.prevConstraints.video as any);

        if (settings) {
          return {
            width: settings.width,
            height: settings.height,
            deviceId: this.state.videoDeviceId as string,
          };
        }
      }
    }

    return this.state.constraints.video;
  }

  // public getSettingsForAudioOffer(audioDeviceId: string | null): { deviceId?: { exact: string } } | null {
  //   return audioDeviceId ? { deviceId: { exact: audioDeviceId } } : null;
  // }

  public async getSupportedResolutions(stream: MediaStream, onlyWidth = false): Promise<any[]> {
    const supported: Resolution[] = [];
    const videoTrack = stream.getVideoTracks()[0];
    if (videoTrack) {
      if (videoTrack.getCapabilities) {
        const capabilities: any = videoTrack.getCapabilities() || null;
        if (capabilities) {
          return Promise.resolve(RESOLUTIONS.filter((resolution) => {
            let comparator = capabilities.width.max >= resolution.width && capabilities.width.min <= resolution.width;
            if (!onlyWidth) {
              comparator = capabilities.width.max >= resolution.width && capabilities.width.min <= resolution.width &&
                capabilities.height.max >= resolution.height && capabilities.height.min <= resolution.height;
            }
            return comparator;
          }));
        }
      } else {
        return Promise.resolve(RESOLUTIONS);
      }
    }
    return Promise.resolve(supported);
  }

  // async getSupportedResolutionsByApplying(incomingStream: MediaStream, onlyWidth = false): Promise<any[]> {
  //   const stream = incomingStream.clone();
  //   const supported: any[] = [];
  //   const videoTrack = stream.getVideoTracks()[0];
  //   if (videoTrack) {
  //     for (const resolution of RESOLUTIONS) {
  //       if (await this.isResolutionSupported(videoTrack, resolution, onlyWidth)) {
  //         supported.push(resolution);
  //       }
  //     }
  //     /* Concurrent version seems buggy
  //     supported = await Promise.all(RESOLUTIONS.map(async (resolution) => {
  //       return this.isResolutionSupported(videoTrack, resolution, onlyWidth).then((supported) => {
  //         return supported ? resolution : false;
  //       });
  //     }));
  //     supported = supported.filter(supported => !!supported);
  //     */
  //     // this.stopStream(stream);
  //     return supported;
  //   } else {
  //     this.stopStream(stream);
  //     return Promise.resolve([]);
  //   }
  // }

  public getStreamResolution(stream: MediaStream): Resolution | null {
    const videoTrack = stream.getVideoTracks()[0];

    if (videoTrack) {
      const settings = videoTrack.getSettings();
      return RESOLUTIONS.find((resolution: Resolution) =>
        resolution.width === settings.width && (resolution.height === settings.height || resolution.alt === settings.height)) || null;
    }

    return null;
  }

  public getStreamVideoId(stream: MediaStream): string | null {
    const videoTrack = stream.getVideoTracks()[0];

    if (videoTrack) {
      const settings = videoTrack.getSettings();
      return settings.deviceId || null;
    }

    return null;
  }

  public getStreamAudioDeviceId(stream: MediaStream): string | null {
    const audioTrack = stream.getAudioTracks()[0];

    if (audioTrack) {
      const settings = audioTrack.getSettings();
      return settings.deviceId || null;
    }

    return null;
  }

  public turnOffWebCamLight(mediaStream: MediaStream | null): void {
    this.stopVideoStreamTracks(mediaStream);
  }

  public stopVideoStreamTracks(mediaStream: MediaStream | null): void {
    if (!mediaStream) {
      return;
    }

    const videoTracks = mediaStream.getVideoTracks();

    videoTracks.forEach((track: MediaStreamTrack) => track.stop());
  }

  public applyResolution(stream: MediaStream, resolution: Resolution, alternateHeight = false): Promise<boolean> {
    const videoTrack = stream.getVideoTracks()[0];
    if (videoTrack) {
      const constraints = {
        width: { ideal: resolution.width },
        height: { ideal: alternateHeight ? resolution.alt : resolution.height },
      };
      return videoTrack.applyConstraints(constraints).then(() => true).catch(async (error) => {
        if (error.name === 'OverconstrainedError' && error.constraint === 'height' && !alternateHeight) {
          return await this.applyResolution(stream, resolution, true);
        } else {
          return false;
        }
      });
    }
    return Promise.resolve(false);
  }

  public stopStream(stream: MediaStream) {
    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }
  }
//
//   private isResolutionSupported(
//     track: MediaStreamTrack,
//     resolution: Resolution,
//     onlyWidth = false,
//     alternateHeight = false
//   ): Promise<boolean> {
//     if (resolution.width && resolution.height) {
//       const constraints: MediaTrackConstraints = {
//         width: { exact: resolution.width },
//         height: resolution.height,
//       };
//       if (!onlyWidth) {
//         constraints.height = { exact: resolution.height };
//       }
//       if (alternateHeight) {
//         constraints.height = { exact: resolution.alt };
//       }
//       return track.applyConstraints(constraints).then(() => true).catch(async (error) => {
//         if (error.name === 'OverconstrainedError' && error.constraint === 'height' && !alternateHeight) {
//           return await this.isResolutionSupported(track, resolution, onlyWidth, true);
//         } else {
//           return false;
//         }
//       });
//     } else {
//       return Promise.resolve(false);
//     }
//   }
}
