import { JanusJS } from '../../../assets/stusan/scripts/janus';
import { VideoType } from '@enums';
import { BehaviorSubject, Subscription } from 'rxjs';

export interface Video {
  stream: MediaStream;
  pluginHandle: JanusJS.PluginHandle;
  id: number;   // Janus handleId
  name: string;
  remote: boolean;
  type: VideoType; // user or screen tile
}

/**
 * Represents user info such as cam and mic statuses.
 * Synced via RTDB WebSockets.
 */
export class UserInfo {
  readonly id: number;    // Janus handle id
  userName = '';
  readonly isLocal: boolean;

  video: boolean;
  audio: boolean;

  readonly options$ = new BehaviorSubject<UserOptions | null>(null);
  options$$: Subscription | null;

  readonly media$ = new BehaviorSubject<UserMedia>(new UserMedia());
  media$$: Subscription | null;

  readonly metrics$ = new BehaviorSubject<UserMetrics>({ heartRate: 0 });
  metrics$$: Subscription | null;

  constructor(id: number, userName: string, isLocal: boolean = false) {
    this.id = id;
    this.userName = userName;
    this.isLocal = isLocal;
  }

  isScreen() {
    return this.userName.endsWith(' screen');
  }
}

export interface UserMetrics {
  heartRate: number;
}

export interface UserOptions {
  country: string;
  countryName: string;
  weather: number;
  city: string;
  timezone: string;
}

export class UserMedia {
  readonly video: boolean;
  readonly audio: boolean;

  static initial = new UserMedia();

  constructor(video: boolean = false, audio: boolean = false) {
    this.video = video;
    this.audio = audio;
  }
}

export interface Audio {
  stream?: MediaStream;
  id?: number;
}

export interface MultiMedia {
  video: Video;
  audio?: Audio;
  zwiftScreen?: Video;
  visible?: boolean;
}


export interface ConnectionInfoModel {
  width?: number;
  height?: number;
  videoCodec?: string;
  frameRate?: number;
  bitrate?: number;
  bitrateString?: string;
}

export interface ScreenShareRtdb {
  screenShareId: number; // janus handle for screen share
  timestamp: number;     // local user time when screen share started. For finding out the latest share purpose.
}
