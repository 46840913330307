<div class="heart-rate-wrapper">
  <div class="heart-rate-bar" [ngClass]="{'is-max': getHeartRatePercentage() >= 90}">
    <div class="infill" [ngStyle]="{width: 100 - getHeartRatePercentage() + '%' }"></div>
  </div>
  <img *ngIf="getHeartRatePercentage() < 90" class="heart-rate-icon" src="assets/gif/heart.gif" alt="Heart Rate">
  <img *ngIf="getHeartRatePercentage() >= 90" class="heart-rate-icon" src="assets/gif/heart-red.gif" alt="Heart Rate">
  <div>
    <span class="heart-rate">{{heartRate}}</span>
    <span class="heart-rate-unit">BPM</span>
  </div>
</div>
