import { StateService } from '@shared/services';
import { RealtimeDatabaseService } from './rtdb.service';
import { Injectable } from '@angular/core';
import { RealtimeDatabaseSubscriptionsService } from './rtdb-subscriptions.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { BehaviorSubject, timer } from 'rxjs';

const HEART_RATE_SERVICE_ID = 0x180D;
const HEART_RATE_MEASUREMENT_CHARACTERISTIC_ID = 0x2A37;
const MAX_CONNECTION_TIMEOUT = 10000;

interface GattServer {
  connect(): Promise<void>;
  disconnect(): Promise<void>;
  connected: boolean;
}

export interface BluetoothDevice {
  name: string;
  id: string;
  gatt: GattServer;
}

@Injectable({ providedIn: 'root' })

export class MetricsService {
  constructor(
    public readonly stateService: StateService,
    private readonly rtdb: RealtimeDatabaseService,
    private readonly rtdbSubscriptionService: RealtimeDatabaseSubscriptionsService,
    private readonly notificationService: NotificationService,
  ) {
  }
  public bluetoothDevice: BluetoothDevice;
  isConnecting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public toggleReadMetrics(checked: boolean): void {
    const userId = this.stateService.userId;
    if(checked) {
      this.stateService.isHeartRateEnabled$.next(true);

      // @ts-ignore
      navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: [HEART_RATE_SERVICE_ID],
      })
        .then((device: any) => {
          this.isConnecting$.next(true);
          this.bluetoothDevice = device;
          try {
            timer(MAX_CONNECTION_TIMEOUT).subscribe(() => {
              if (!device.gatt.connected && checked) {
                this.notificationService.showError('Please make sure this sensor is turned on and is not connected to another device');
                this.stateService.isHeartRateEnabled$.next(false);
                this.isConnecting$.next(false);
              }
            });
            return device.gatt.connect();
          } catch (error) {
            console.error('Problem in connect bluetooth', error);
            this.notificationService.showError('Problem with requesting bluetooth device.');
            this.stateService.isHeartRateEnabled$.next(false);
          }
        })
        .then((server: any) => server.getPrimaryService(HEART_RATE_SERVICE_ID))
        .then((service: any) => service.getCharacteristic(HEART_RATE_MEASUREMENT_CHARACTERISTIC_ID))
        .then((characteristic: any) => {
          console.log(characteristic, '!!!!!!!!!!! characteristic !!!!!!!!!!!');
          characteristic.startNotifications();
          characteristic.oncharacteristicvaluechanged = handleCharacteristicValueChanged;
        })
        .catch((error: any) => {
          console.error('Problem in read/parse bluetooth', error);
          this.stateService.isHeartRateEnabled$.next(false);
          this.isConnecting$.next(false);
        });
    } else {
      try {
        this.bluetoothDevice.gatt.disconnect();
        this.stateService.isHeartRateEnabled$.next(false);
        this.rtdbSubscriptionService.sendHeartRate(userId, 0);
        this.stateService.heartRate$.next(0);
      } catch (error) {
        console.error('Problem in disconnect bluetooth', error);
      }
    }

    const handleCharacteristicValueChanged = (event: any) => {
      const valueParsed = event.target.value?.getInt8(1);
      const value = valueParsed < 0 ? 256 - Math.abs(valueParsed) : valueParsed;
      console.log(value, '!!!!!!!!!!! value !!!!!!!!!!!');
      this.stateService.heartRate$.next(value);
      this.rtdbSubscriptionService.sendHeartRate(userId, value);
      this.isConnecting$.next(false);
    };
  }
}
