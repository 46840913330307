import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutType, ThemeType, BackgroundEffectsType } from '@enums';
import { environment } from '../../../../environments/environment';
import { CustomizationService } from '../../services/customization.service';
import { SettingsChanges } from '../../models/settings-changes.model';
import { PlayLeaveSoundState } from '../../enums/play-leave-sound-state';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public isRecordingBot = false;

  get isCamBanned() { // user banned access for cam
    return (this.getSavedState('isCamBanned') == null) ? false : this.getSavedState('isCamBanned') === 'true';
  }
  set isCamBanned(value: boolean) {
    this.saveState('isCamBanned', value);
  }

  public playLeaveSound: PlayLeaveSoundState = PlayLeaveSoundState.default;

  get videoEnabled() { // flag for audioTrack.enabled(paused from docs)
    return (this.getSavedState('videoEnabled') == null) ? true : this.getSavedState('videoEnabled') === 'true';
  }

  set videoEnabled(value: boolean) {
    this.saveState('videoEnabled', value);
  }

  get audioEnabled() { // flag for audioTrack.enabled(paused from docs)
    return (this.getSavedState('audioEnabled') == null) ? true : this.getSavedState('audioEnabled') === 'true';
  }

  set audioEnabled(value: boolean) {
    this.saveState('audioEnabled', value);
  }

  constraints$ = new BehaviorSubject<{ [key: string]: any }>({
    video: { aspectRatio: 1.77777778, width: 1280, height: 720, frameRate: 30 },
    audio: {
      echoCancellation: this.customizationService.config.mediaEchoCancellation,
      autoGainControl: this.customizationService.config.mediaAutoGainControl,
      noiseSuppression: this.customizationService.config.mediaNoiseSuppression,
    }
  });

  get constraints(): any {
    return this.constraints$.getValue();
  }

  set constraints(constraints: any | null) {
    this.constraints$.next(constraints);
  }

  resolution: { width: 'any' | number; height: 'any' | number } = { width: 'any', height: 'any' };

  roomId$ = new BehaviorSubject<string | number | null>(this.getRoomId() || null);

  get roomId(): string | number | null {
    return this.roomId$.getValue();
  }

  set roomId(value: string | number | null) {
    this.roomId$.next(value);
  }

  get roomIdString(): string {
    const value = this.roomId$.getValue();
    if (value === null) {
      return '';
    } else {
      return '' + value;
    }
  }

  currentVideoId$ = new BehaviorSubject<string | number | null>(null);

  get currentVideoId(): string | number | null {
    return this.currentVideoId$.getValue();
  }

  set currentVideoId(value: string | number | null) {
    this.currentVideoId$.next(value);
  }

  likesCount$ = new BehaviorSubject<number>(0);

  get likesCount(): number {
    return this.likesCount$.getValue();
  }

  set likesCount(value: number) {
    this.likesCount$.next(value);
  }

  starsCount$ = new BehaviorSubject<number | null>(null);

  get starsCount(): number | null {
    return this.starsCount$.getValue();
  }

  set starsCount(value: number | null) {
    this.starsCount$.next(value);
  }

  userName$ = new BehaviorSubject<string | null>(this.getSavedState('userName') || null);

  get userName(): string | null {
    return this.userName$.getValue();
  }

  set userName(value: string | null) {
    this.saveState('userName', value);
    this.userName$.next(value);
  }

  roomName$ = new BehaviorSubject<string | null>(null);

  get roomName() {
    return this.roomName$.getValue();
  }

  set roomName(value: string | null) {
    this.roomName$.next(value);
  }

  public isHeartRateEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isHeartRateEnabled() {
    return this.isHeartRateEnabled$.getValue();
  }

  set isHeartRateEnabled(value: boolean) {
    this.saveState('isHeartRateEnabled', value);
  }

  heartRate$ = new BehaviorSubject<number>(0);

  get heartRate() {
    return this.heartRate$.getValue();
  }

  set heartRate(value: number) {
    this.heartRate$.next(value);
  }

  /**
   * Assume roomName is set. Otherwise throws error.
   * Can be used to reduce code logic.
   */
  getRoomName(): string {
    const value = this.roomName$.getValue();
    if (!value) {
      throw new Error('Room name is not ready. Wrong state.');
    }
    return value;
  }

  webinarName$ = new BehaviorSubject<string | null>(null);

  get webinarName() {
    return this.webinarName$.getValue();
  }

  set webinarName(value: string | null) {
    this.webinarName$.next(value);
  }

  startTime$ = new BehaviorSubject<number | null>(null);

  get startTime() {
    return this.startTime$.getValue();
  }

  set startTime(value: number | null) {
    this.startTime$.next(value);
  }

  isLoggedIn$ = new BehaviorSubject<boolean | null>(false);

  get isLoggedIn() {
    return this.isLoggedIn$.getValue();
  }

  set isLoggedIn(value: boolean | null) {
    this.isLoggedIn$.next(value);
  }

  isMicEnabled$ = new BehaviorSubject<boolean | null>(this.getSavedState('isMicEnabled', 'boolean') || true);

  get isMicEnabled() {
    return this.isMicEnabled$.getValue();
  }

  set isMicEnabled(value: boolean | null) {
    this.saveState('isMicEnabled', value);
    this.isMicEnabled$.next(value);
  }

  isCamEnabled$ = new BehaviorSubject<boolean | null>(this.getSavedState('isCamEnabled', 'boolean'));

  get isCamEnabled() {
    return this.isCamEnabled$.value;
  }

  set isCamEnabled(value: boolean | null) {
    this.isCamEnabled$.next(value);
  }

  get simulcastEnabled(): boolean {
    return this.getSavedState('simulcast', 'boolean');
  }

  set simulcastEnabled(enabled: boolean) {
    this.saveState('simulcast', enabled);
  }

  theme$ = new BehaviorSubject<ThemeType>(this.getTheme());

  get theme() {
    return this.theme$.getValue();
  }

  set theme(value: ThemeType) {
    if (!value) {
      this.saveState('theme', null);
      value = ThemeType.light;
    } else {
      this.saveState('theme', value);
    }
    this.theme$.next(value);
  }

  audioDeviceId$ = new BehaviorSubject<string | null>(this.getSavedState('audioDeviceId') || null);

  get audioDeviceId() {
    return this.audioDeviceId$.getValue();
  }

  set audioDeviceId(value: string | null) {
    this.saveState('audioDeviceId', value);
    this.audioDeviceId$.next(value);
    this.settingsChanges = { ...this.settingsChanges, audioDeviceId: value };
  }

  videoDeviceId$ = new BehaviorSubject<string | null>(this.getSavedState('videoDeviceId') || null);

  get videoDeviceId() {
    return this.videoDeviceId$.getValue();
  }

  set videoDeviceId(value: string | null) {
    this.saveState('videoDeviceId', value);
    this.videoDeviceId$.next(value);
    this.settingsChanges = { ...this.settingsChanges, videoDeviceId: value };
  }

  userId$ = new BehaviorSubject<string>(this.getRandomId());

  get userId() {
    return this.userId$.getValue();
  }

  set userId(value: string) {
    this.userId$.next(value);
    this.videoDeviceId$.next(value);
  }

  layoutType$ = new BehaviorSubject<LayoutType>(LayoutType.tile);

  get layoutType() {
    return this.layoutType$.getValue();
  }

  set layoutType(value: LayoutType) {
    this.layoutType$.next(value);
  }

  isChatShown$ = new BehaviorSubject<boolean>(false);

  get isChatShown() {
    return this.isChatShown$.getValue();
  }

  set isChatShown(value: boolean) {
    this.isChatShown$.next(value);
  }

  isParticipantsShown$ = new BehaviorSubject<boolean>(false);

  get isParticipantsShown() {
    return this.isParticipantsShown$.getValue();
  }

  set isParticipantsShown(value: boolean) {
    this.isParticipantsShown$.next(value);
  }

  public raisedToTopVideoId$ = new BehaviorSubject<number | null | undefined>(null);

  get raisedToTopVideoId() {
    return this.raisedToTopVideoId$.getValue();
  }

  set raisedToTopVideoId(value: number | null | undefined) {
    this.raisedToTopVideoId$.next(value);
  }

  isMobileMenuShown$ = new BehaviorSubject<boolean>(false);

  get isMobileMenuShown() {
    return this.isMobileMenuShown$.getValue();
  }

  set isMobileMenuShown(value: boolean) {
    this.isMobileMenuShown$.next(value);
  }

  isSettingsShown$ = new BehaviorSubject<boolean>(false);

  get isSettingsShown() {
    return this.isSettingsShown$.getValue();
  }

  set isSettingsShown(value: boolean) {
    this.isSettingsShown$.next(value);
  }

  isProfileShown$ = new BehaviorSubject<boolean>(false);

  get isProfileShown() {
    return this.isProfileShown$.getValue();
  }

  set isProfileShown(value: boolean) {
    this.isProfileShown$.next(value);
  }

  private isJoinedRoom$ = new BehaviorSubject<boolean>(false);

  get isJoinedRoom() {
    return this.isJoinedRoom$.getValue();
  }

  set isJoinedRoom(value: boolean) {
    this.isJoinedRoom$.next(value);
  }

  public subscribeOnIsJoinedRoom(): Observable<boolean> {
    return this.isJoinedRoom$.asObservable();
  }

  public isMobileMenuHidden$ = new BehaviorSubject<boolean>(false);

  get isMobileMenuHidden() {
    return this.isMobileMenuHidden$.getValue();
  }

  set isMobileMenuHidden(value: boolean) {
    this.isMobileMenuHidden$.next(value);
  }

  public isLikeButtonDisabled$ = new BehaviorSubject<boolean>(false);

  get isLikeButtonDisabled() {
    return this.isLikeButtonDisabled$.getValue();
  }

  set isLikeButtonDisabled(value: boolean) {
    this.isLikeButtonDisabled$.next(value);
  }

  // By default, there is no voice activity
  // Show pop-up 'Speaking?' when mic and camera detects voice/lips movement
  public isVoiceActive$ = new BehaviorSubject<boolean>(false);

  public isLayoutChanged$ = new BehaviorSubject<boolean>(false);

  get isLayoutChanged() {
    return this.isLayoutChanged$.getValue();
  }

  set isLayoutChanged(value: boolean) {
    this.isLayoutChanged$.next(value);
  }

  public confirmEndCall$ = new BehaviorSubject<boolean>(false);

  get confirmEndCall() {
    return this.confirmEndCall$.getValue();
  }

  set confirmEndCall(value: boolean) {
    this.confirmEndCall$.next(value);
  }

  observedFields = [this.roomId, this.userName, this.roomName, this.startTime, this.isLoggedIn, this.isMicEnabled, this.isCamEnabled,
    this.theme, this.audioDeviceId, this.videoDeviceId, this.layoutType, this.isChatShown, this.isSettingsShown];


  public isScreenShareEnabled$ = new BehaviorSubject<boolean>(false);

  get isScreenShareEnabled() {
    return this.isScreenShareEnabled$.getValue();
  }

  set isScreenShareEnabled(value: boolean) {
    this.isScreenShareEnabled$.next(value);
  }

  public isScreenShareClicked$ = new BehaviorSubject<boolean>(false);

  get isScreenShareClicked() {
    return this.isScreenShareClicked$.getValue();
  }

  set isScreenShareClicked(value: boolean) {
    this.isScreenShareClicked$.next(value);
  }

  public isScreenShareActive$ = new BehaviorSubject<number | null>(null);

  get isScreenShareActive() {
    return this.isScreenShareActive$.getValue();
  }

  set isScreenShareActive(value: number | null) {
    this.isScreenShareActive$.next(value);
  }

  public isZwiftShareEnabled$ = new BehaviorSubject<boolean>(false);

  get isZwiftShareEnabled() {
    return this.isZwiftShareEnabled$.getValue();
  }

  set isZwiftShareEnabled(value: boolean) {
    this.isZwiftShareEnabled$.next(value);
  }

  public isZwiftShareActive$ = new BehaviorSubject<number | null>(null);

  get isZwiftShareActive() {
    return this.isZwiftShareActive$.getValue();
  }

  set isZwiftShareActive(value: number | null) {
    this.isZwiftShareActive$.next(value);
  }

  public isAnimationActive$ = new BehaviorSubject<boolean | null>(false);

  get isAnimationActive() {
    return this.isAnimationActive$.getValue();
  }

  set isAnimationActive(value: boolean | null) {
    this.isAnimationActive$.next(value);
  }

  public isPreviewStreamActive$ = new BehaviorSubject<boolean>(false);

  get isPreviewStreamActive() {
    return this.isPreviewStreamActive$.getValue();
  }

  set isPreviewStreamActive(value: boolean) {
    this.isPreviewStreamActive$.next(value);
  }

  public isOwnVideoActive$ = new BehaviorSubject<any>(null);

  get isOwnVideoActive() {
    return this.isOwnVideoActive$.getValue();
  }

  set isOwnVideoActive(value: any) {
    this.isOwnVideoActive$.next(value);
  }

  public isRecordingEnabled$ = new BehaviorSubject<boolean>(true);

  get isRecordingEnabled() {
    return this.isRecordingEnabled$.getValue();
  }

  set isRecordingEnabled(value: boolean) {
    this.isRecordingEnabled$.next(value);
  }

  public isHandRaised$ = new BehaviorSubject<boolean>(false);

  get isHandRaised() {
    return this.isHandRaised$.getValue();
  }

  set isHandRaised(value: boolean) {
    this.isHandRaised$.next(value);
  }

  public currentBackgroundEffect$ =
    new BehaviorSubject<BackgroundEffectsType>(this.getSavedState('currentBackgroundEffect', 'string') || BackgroundEffectsType.none);

  get currentBackgroundEffect() {
    return this.currentBackgroundEffect$.getValue();
  }

  set currentBackgroundEffect(value: BackgroundEffectsType) {
    this.saveState('currentBackgroundEffect', value);
    this.currentBackgroundEffect$.next(value);
    this.settingsChanges = { ...this.settingsChanges, currentBackgroundEffect: value };
  }

  public settingsChanges$ =
    new BehaviorSubject<SettingsChanges | null>(this.getSavedState('settingsChanges', 'object') || null);

  get settingsChanges(): SettingsChanges {
    const currentBackgroundEffect = this.currentBackgroundEffect;
    const videoDeviceId = this.videoDeviceId;
    const audioDeviceId = this.audioDeviceId;

    return { currentBackgroundEffect, videoDeviceId, audioDeviceId };
  }

  set settingsChanges({ currentBackgroundEffect, videoDeviceId, audioDeviceId }: SettingsChanges) {
    this.saveState('currentBackgroundEffect', currentBackgroundEffect);
    this.saveState('videoDeviceId', videoDeviceId);
    this.saveState('audioDeviceId', audioDeviceId);
    this.settingsChanges$.next({ currentBackgroundEffect, videoDeviceId, audioDeviceId });
  }

  userToken$ = new BehaviorSubject<string | null>(this.getSavedState('userToken') || null);

  get userToken(): string | null {
    return this.userToken$.getValue();
  }

  set userToken(value: string | null) {
    this.saveState('userToken', value);
    this.userToken$.next(value);
  }

  public get isAudioEnabled(): boolean {
    return !this.isCamBanned && this.audioEnabled;
  }

  public get isVideoEnabled(): boolean {
    return !this.isCamBanned && this.videoEnabled;
  }

  constructor(public customizationService: CustomizationService) {
    // Save values when user refresh page
  }

  getRoomId() {
    const roomIdData = this.getSavedState<string>('roomId');
    if (!!roomIdData) {
      if (environment.janus.stringRoomIds) {
        return roomIdData;
      } else {
        return parseInt(roomIdData, 10);
      }
    } else {
      return null;
    }
  }

  getTheme(): ThemeType {
    return this.getSavedState('theme') || ThemeType.light;
  }


  getSavedState<T>(key: string, type?: string): T | false {
    // console.log(`getSavedState ${key}`);
    try {
      let saved = localStorage.getItem(key) as unknown;
      if (!!type) {
        saved = this.castValueToType(saved, type);
      }
      if (saved === 'null') {
        saved = null;
      }
      return saved as T;
    } catch (e) {
      console.log('Storage is not available.', this.getAnyClass(e));
      if (e instanceof DOMException) {
        console.log('Using mock responses.');
        if (e instanceof DOMException && key === 'roomName') {
          return ('room123456' as unknown) as T;
        } else if (e instanceof DOMException && key === 'roomId') {
          return (2687219796 as unknown) as T;
        } else if (e instanceof DOMException && key === 'isLoggedIn') {
          return (true as unknown) as T;
        } else if (e instanceof DOMException && key === 'userName') {
          return ('User' as unknown) as T;
        }
      }
      return (null as unknown) as T;
    }
  }

  private getAnyClass(obj: any): string {
    if (typeof obj === 'undefined') {
      return 'undefined';
    }
    if (obj === null) {
      return 'null';
    }
    return obj.constructor.name;
  }

  saveState(key: string, value: any): void {
    try {
      if (value === null || value === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value.toString());
      }
    } catch (e) {
      console.log('Storage is not available.', e);
    }
  }

  castValueToType(value: unknown, type: string) {
    if (type === 'boolean') {
      if (value === null || value === undefined) {
        return null;
      }
      if (!value) {
        return false;
      }
      if (typeof value === 'string') {
        return JSON.parse(value);
      }
    }
    if (type === 'object') {
      if (typeof value === 'string') {
        try {
          return JSON.parse(value);
        } catch (error) {
          console.log('[State]', error, value);
        }
      }
    }
    return value;
  }

  clearState(): void {
    this.roomId = null;
    this.startTime = null;
    this.isLoggedIn = false;
  }

  clearAllState(): void {
    // localStorage.clear();
    this.saveState('audioDeviceId', null);
    this.saveState('videoDeviceId', null);
  }

  getRandomId(): string {
    return String(Math.floor(1000000000000000 + Math.random() * 9000000000000000));
  }
}
