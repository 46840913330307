import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import packageJson from '../package.json';
// import { CaptureConsole } from '@sentry/integrations';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ensureGlobalObject } from './app/stusan/utils/ensure-global-object.util';

if (environment.production) {
  enableProdMode();
}

// if Sentry enabled
const isAhrefBot = navigator.userAgent.includes('AhrefsBot');
if (environment.sentryDSN && !isAhrefBot) {
  Sentry.init({
    release: `${packageJson.name}-${environment.envName}@${environment.buildNumber}`,
    dsn: environment.sentryDSN,
    environment: environment.envName,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [environment.sentryOrigin],
        routingInstrumentation: Sentry.routingInstrumentation,
        idleTimeout: 10000,
      }),
      // new CaptureConsole(),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['fancybox.getInstance'],   // sample how to suppress particular errors
  });
} else {
  console.log('Skipping Sentry reporting as sentryDSN is not set.');
}
ensureGlobalObject('APP').version = packageJson.version;

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
